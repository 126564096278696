<template>
    <content-wrapper
        :breadcrumbs="breadcrumbs"
    >
        <v-row>
            <v-col cols="12" lg="4">
                <v-card outlined height="100%" class="pa-6">
                    <schedule-lesson-details
                        :item="lesson"
                    />
                </v-card>
            </v-col>
            <v-col cols="12" lg="8">
                <v-card outlined height="100%" class="pa-6">
                    <schedule-lesson-head
                        v-if="lesson"
                        :item="lesson"
                        @submit="fetchScheduleLesson"
                    />
                </v-card>
            </v-col>
            <v-col cols="12">
                <v-sheet outlined rounded class="overflow-hidden">
                    <media-table
                        v-if="lesson"
                        :title="$trans('Documents')"
                        hide-size
                        shared-type="course"
                        :shared-uuid="lesson.course_uuid"
                        :payload="{ author: lesson.teacher.auth.uuid }"
                        :editable="$auth.isTeacher()"
                        viewable
                    />
                </v-sheet>
            </v-col>
        </v-row>
    </content-wrapper>
</template>

<script>
import mobileBreakpointChecker from '@/mixins/mobileBreakpointChecker'
import ContentWrapper from '@/components/ContentWrapper'
import { getScheduleLesson } from '@apps/school/api/schedule'
import ScheduleLessonDetails from '@apps/school/components/Schedule/ScheduleMember/ScheduleLessonDetails'
import ScheduleLessonHead from '@apps/school/components/Schedule/ScheduleMember/ScheduleLessonHead'
import moment from 'moment/moment'
import MediaTable from '@apps/media/components/MediaTable'

export default {
    name: 'ScheduleLesson',
    components: {
        MediaTable,
        ScheduleLessonHead,
        ScheduleLessonDetails,
        ContentWrapper
    },
    mixins: [
        mobileBreakpointChecker
    ],
    computed: {
        lessonUuid() {
            return this.$route.params.uuid
        },
        filterKey() {
            return this.$auth.isStudent()
                ? 'group'
                : 'teacher'
        },
        filterValue() {
            return this.$auth.isStudent()
                ? this.$auth.member.group_uuid
                : this.$auth.member.uuid
        },
        breadcrumbs() {
            const breadcrumbs = [
                {
                    text: this.$trans('Schedule'),
                    to: { name: 'schedule' }
                }
            ]

            if (this.lesson) {
                breadcrumbs.push({
                    text: moment(this.lesson.begin).format('DD MMMM, YYYY')
                })
            }

            return breadcrumbs
        }
    },
    data() {
        return {
            lesson: null
        }
    },
    mounted() {
        this.fetchScheduleLesson()
    },
    methods: {
        async fetchScheduleLesson() {
            this.$lprogress.begin()

            try {
                const payload = {
                    begin: this.begin,
                    end: this.end,
                    [this.filterKey]: this.filterValue
                }

                const response = await getScheduleLesson(this.lessonUuid, payload)
                this.lesson = response.data.data
            } catch (error) {
                // ...
            } finally {
                this.$lprogress.end()
            }
        }
    }
}
</script>

<style lang=scss>

</style>
