<template>
    <div v-if="url && lessonUuid">
        <v-alert
            v-if="inviteSentAt"
            :icon="false"
            prominent
            text
            type="info"
            class="caption"
        >
            <span>{{ $trans('hint.meeting_invite.sent_invite_at', {date: inviteSentAt}) }}</span>
            <span v-if="inviteSentToGroup" class="text-lowercase">
                {{ $trans('hint.meeting_invite.sent_to_group') }}
            </span>
            <span v-if="inviteSentToGroup && inviteSentToOther" class="text-lowercase">
                (+{{ inviteSentToOther }} emails)
            </span>
            <span v-if="!inviteSentToGroup && inviteSentToOther" class="text-lowercase">
                / {{ inviteSentToOther }} emails
            </span>
        </v-alert>
        <div class="caption text-truncate mt-2">{{ $trans('hint.meeting_invite.available_send_invite') }}</div>
        <div
            class="d-flex align-center text-truncate flex-fill"
            style="max-width: 100%"
        >
            <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                    <v-btn
                        color="primary"
                        icon
                        v-bind="attrs"
                        v-on="on"
                        v-clipboard:copy="url"
                        v-clipboard:success="onCopy"
                        class="mr-1"
                    >
                        <w-icon small value="COPY"/>
                    </v-btn>
                </template>
                <span>{{ $trans('Copy to clipboard') }}</span>
            </v-tooltip>
            <a
                :href="url"
                target="_blank"
                class="text-truncate"
            >
                {{ url }}
            </a>
            <v-spacer/>
            <v-tooltip
                bottom
            >
                <template v-slot:activator="{ on, attrs }">
                    <v-btn
                        icon
                        color="primary"
                        v-bind="attrs"
                        v-on="on"
                        @click="isDialog = true"
                    >
                        <w-icon
                            value="SEND"
                        />
                    </v-btn>
                </template>
                <span>{{ $trans('Send invite') }}</span>
            </v-tooltip>
        </div>
        <w-dialog-drawer
            v-model="isDialog"
            :title="$trans('Send invite')"
            :loading="loading"
        >
            <div>
                <div class="px-5">
                    <v-checkbox
                        v-model="isSendToGroup"
                        :label="$trans('hint.meeting_invite.is_send_to_group')"
                    />
                    <div class="caption mb-2">{{ $trans('hint.meeting_invite.additional_emails') }}</div>
                    <v-combobox
                        v-model="additionalEmails"
                        :filter="filter"
                        :hide-no-data="!search"
                        :items="additionalEmailsItems"
                        :search-input.sync="search"
                        hide-selected
                        :label="$trans('Recipients')"
                        :error.sync="isAdditionalEmailsInvalid"
                        :rules="[rules.emailValidator]"
                        multiple
                        small-chips
                        outlined
                        clearable
                        @input="isAdditionalEmailsInvalid = false"
                    >
                        <template v-slot:no-data>
                            <v-list-item>
                                <span class="pr-2 caption font-weight-bold">{{ $trans('Add') }}</span>
                                <v-chip
                                    label
                                    small
                                >
                                    {{ search }}
                                </v-chip>
                            </v-list-item>
                        </template>
                        <template v-slot:selection="{ attrs, item, parent, selected }">
                            <v-chip
                                v-if="item === Object(item)"
                                v-bind="attrs"
                                :input-value="selected"
                                label
                                small
                            >
                          <span class="pr-2">
                            {{ item.text }}
                          </span>
                                <v-icon
                                    small
                                    @click="parent.selectItem(item)"
                                >
                                    $delete
                                </v-icon>
                            </v-chip>
                        </template>
                        <template v-slot:item="{ index, item }">
                            <v-text-field
                                v-if="editing === item"
                                v-model="editing.text"
                                autofocus
                                flat
                                background-color="transparent"
                                hide-details
                                solo
                                @keyup.enter="edit(index, item)"
                            ></v-text-field>
                            <v-chip
                                v-else
                                label
                                small
                            >
                                {{ item.text }}
                            </v-chip>
                            <v-spacer></v-spacer>
                            <v-list-item-action @click.stop>
                                <div class="d-flex align-center">
                                    <v-btn
                                        icon
                                        small
                                        color="success"
                                        @click.stop.prevent="edit(index, item)"
                                    >
                                        <v-icon small>{{ editing !== item ? 'mdi-pencil' : 'mdi-check' }}</v-icon>
                                    </v-btn>
                                    <v-btn
                                        icon
                                        small
                                        color="error"
                                        @click.stop.prevent="onDeleteAdditionalItem(index, item)"
                                    >
                                        <v-icon small>mdi-close</v-icon>
                                    </v-btn>
                                </div>
                            </v-list-item-action>
                        </template>
                    </v-combobox>
                </div>
                <v-divider class="my-4"/>
                <div class="d-flex px-5 pb-4">
                    <v-spacer/>
                    <v-btn
                        :disabled="isSubmitDisabled"
                        :loading="loading"
                        color="primary"
                        @click="onSendInvite"
                    >
                        {{ $trans('Send') }}
                        <w-icon value="SEND" right/>
                    </v-btn>
                </div>
            </div>
        </w-dialog-drawer>
    </div>
</template>

<script>
import mobileBreakpointChecker from '@/mixins/mobileBreakpointChecker'
import { emailValidator } from '@/utils/validation'
import { sendInviteToOnlineLesson, updateLessonOptions } from '@apps/school/api/lesson'
import hasAuthApiMixin from '@apps/auth/mixins/hasAuthApiMixin'
import moment from 'moment'
import Debugger from '@/utils/Debugger'

export default {
    name: 'ScheduleMeetingUrl',
    mixins: [
        mobileBreakpointChecker,
        hasAuthApiMixin
    ],
    props: {
        lessonUuid: {
            type: String,
            default: null
        },
        options: {
            type: Object,
            default: function () {
                return {}
            }
        },
        url: {
            type: String,
            default: null
        }
    },
    computed: {
        isSubmitDisabled() {
            return (!this.isSendToGroup &&
                this.additionalEmails.length === 0) ||
                this.isAdditionalEmailsInvalid
        },
        inviteSentAt() {
            const date = this.options.invite_sent_at

            return date ? moment(date).format('DD.MM.YYYY HH:mm') : null
        },
        inviteSentToGroup() {
            return !!this.options.invite_sent_to_group
        },
        inviteSentToOther() {
            return this.options.invite_sent_to_other || false
        },
        scheduleMeetingEmails() {
            return this.authSettings.schedule_meeting_emails || []
        }
    },
    data: () => ({
        loading: false,
        isDialog: false,
        activator: null,
        attach: null,
        editing: null,
        editingIndex: -1,
        additionalEmailsItems: [],
        rules: {
            emailValidator: v => {
                const vs = v.map(o => o.text)
                return emailValidator(vs)
            }
        },
        isSendToGroup: false,
        isAdditionalEmailsInvalid: false,
        additionalEmails: [],
        x: 0,
        y: 0,
        search: null,
    }),
    watch: {
        additionalEmails(val, prev) {
            if (val.length === prev.length) return

            this.additionalEmails = val.map(v => {
                if (typeof v === 'string') {
                    v = {
                        text: v
                    }

                    this.additionalEmailsItems.push(v)
                }

                return v
            })
        }
    },
    mounted() {
        this.additionalEmailsItems = this.scheduleMeetingEmails.map(o => ({text: o}))
    },
    methods: {
        onCopy() {
            this.$notification.make(this.$trans('Copied to clipboard'))
                .open()
        },
        edit(index, item) {
            if (!this.editing) {
                this.editing = item
                this.editingIndex = index
            } else {
                this.editing = null
                this.editingIndex = -1
            }
        },
        onDeleteAdditionalItem(index, item) {
            this.additionalEmailsItems = this.additionalEmailsItems.filter(o => o.text !== item.text)
        },
        filter(item, queryText, itemText) {
            if (item.header) return false

            const hasValue = val => val != null ? val : ''

            const text = hasValue(itemText)
            const query = hasValue(queryText)

            return text.toString()
                .toLowerCase()
                .indexOf(query.toString().toLowerCase()) > -1
        },
        async onSendInvite() {
            if(this.isSubmitDisabled) {
                this.$notification.make(this.$trans('hint.meeting_invite.no_recipients'))
                    .setType(this.$notification.TYPE.ERROR)
                    .open()

                return
            }

            this.loading = true

            try {
                const additional_emails = this.additionalEmails.map(o => o.text)

                const lessonOptions = {
                    invite_sent_to_group: this.isSendToGroup,
                    invite_sent_to_other: additional_emails.length,
                    invite_sent_at: Date.now()
                }

                const memberSettings = {
                    schedule_meeting_emails: this.additionalEmails.map(o => o.text)
                }

                const data = {
                    meeting_url: this.url,
                    is_send_to_group: this.isSendToGroup,
                    additional_emails
                }

                // Send invite
                await sendInviteToOnlineLesson(this.lessonUuid, data)

                // Setup log about invite
                this.$notification.disabled()
                await updateLessonOptions(this.lessonUuid, { options: lessonOptions })

                // Setup additional emails suggestion
                this.$notification.disabled()
                await this.updateAuthSettings(memberSettings)

                this.$emit('sent')
            } catch (error) {
                Debugger.log(error)
            } finally {
                this.loading = false
                this.isDialog = false
            }
        }
    }
}
</script>

<style lang=scss>

</style>
