<template>
    <div>
        <w-simple-form
            :title="$trans('Details')"
            :fields="fields"
            :item="itemComputed"
            :editable="isEditable"
            through-preview
            @update="onUpdateDetails"
        >
            <template #preview.meeting_url="{ item }">
                <schedule-meeting-url
                    :lesson-uuid="item.uuid"
                    :url="item.meeting_url"
                    :options="item.options"
                    @sent="$emit('submit')"
                />
            </template>
        </w-simple-form>
    </div>
</template>

<script>
import { updateLessonOptions } from '@apps/school/api/lesson'
import moment from 'moment/moment'
import { urlValidator } from '@/utils/validation'
import ScheduleMeetingUrl from '@apps/school/components/Schedule/ScheduleMeetingUrl'

export default {
    name: 'ScheduleLessonHead',
    components: { ScheduleMeetingUrl },
    props: {
        item: {
            type: Object,
            required: true
        }
    },
    computed: {
        itemComputed() {
            const {
                title,
                description,
                options,
                uuid
            } = this.item

            return {
                uuid,
                title,
                description,
                options: options || {},
                meeting_url: options ? options.meeting_url : null
            }
        },
        fields() {
            const fields = [
                {
                    type: 'v-text-field',
                    name: 'title',
                    props: {
                        outlined: true,
                        dense: true,
                        label: this.$trans('Theme'),
                        clearable: true
                    }
                },
                {
                    type: 'v-textarea',
                    name: 'description',
                    props: {
                        outlined: true,
                        dense: true,
                        label: this.$trans('Annotation'),
                        clearable: true
                    }
                }
            ]

            if(this.item.mode === 'online') {
                fields.push({
                    type: 'v-text-field',
                    name: 'meeting_url',
                    props: {
                        outlined: true,
                        dense: true,
                        label: this.$trans('Meeting url'),
                        clearable: true,
                        rules: [
                            urlValidator
                        ]
                    }
                })
            }

            return fields
        },
        isEditable() {
            return this.$auth.isTeacher() &&
                moment(this.item.begin).diff(moment(), 'minutes') > 0
        }
    },
    methods: {
        async onUpdateDetails({
            title,
            description,
            meeting_url
        }, item) {
            const data = {
                title,
                description,
                options: { meeting_url }
            }

            this.$lprogress.begin()

            try {
                await updateLessonOptions(item.uuid, data)

                this.$emit('submit')
            } catch (error) {

            } finally {
                this.$lprogress.end()
            }
        }
    }
}
</script>

<style lang=scss>

</style>
