import store from '@/store'

export default {
    computed: {
        updateAuthSettings() {
            return async settings => {
                await store.dispatch('auth/updateAuthSettings', settings)
            }
        },
        authSettings() {
            return this.$store.getters['auth/settings']
        }
    }
}
