var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.url && _vm.lessonUuid)?_c('div',[(_vm.inviteSentAt)?_c('v-alert',{staticClass:"caption",attrs:{"icon":false,"prominent":"","text":"","type":"info"}},[_c('span',[_vm._v(_vm._s(_vm.$trans('hint.meeting_invite.sent_invite_at', {date: _vm.inviteSentAt})))]),(_vm.inviteSentToGroup)?_c('span',{staticClass:"text-lowercase"},[_vm._v(" "+_vm._s(_vm.$trans('hint.meeting_invite.sent_to_group'))+" ")]):_vm._e(),(_vm.inviteSentToGroup && _vm.inviteSentToOther)?_c('span',{staticClass:"text-lowercase"},[_vm._v(" (+"+_vm._s(_vm.inviteSentToOther)+" emails) ")]):_vm._e(),(!_vm.inviteSentToGroup && _vm.inviteSentToOther)?_c('span',{staticClass:"text-lowercase"},[_vm._v(" / "+_vm._s(_vm.inviteSentToOther)+" emails ")]):_vm._e()]):_vm._e(),_c('div',{staticClass:"caption text-truncate mt-2"},[_vm._v(_vm._s(_vm.$trans('hint.meeting_invite.available_send_invite')))]),_c('div',{staticClass:"d-flex align-center text-truncate flex-fill",staticStyle:{"max-width":"100%"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({directives:[{name:"clipboard",rawName:"v-clipboard:copy",value:(_vm.url),expression:"url",arg:"copy"},{name:"clipboard",rawName:"v-clipboard:success",value:(_vm.onCopy),expression:"onCopy",arg:"success"}],staticClass:"mr-1",attrs:{"color":"primary","icon":""}},'v-btn',attrs,false),on),[_c('w-icon',{attrs:{"small":"","value":"COPY"}})],1)]}}],null,false,1370571708)},[_c('span',[_vm._v(_vm._s(_vm.$trans('Copy to clipboard')))])]),_c('a',{staticClass:"text-truncate",attrs:{"href":_vm.url,"target":"_blank"}},[_vm._v(" "+_vm._s(_vm.url)+" ")]),_c('v-spacer'),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","color":"primary"},on:{"click":function($event){_vm.isDialog = true}}},'v-btn',attrs,false),on),[_c('w-icon',{attrs:{"value":"SEND"}})],1)]}}],null,false,3834039878)},[_c('span',[_vm._v(_vm._s(_vm.$trans('Send invite')))])])],1),_c('w-dialog-drawer',{attrs:{"title":_vm.$trans('Send invite'),"loading":_vm.loading},model:{value:(_vm.isDialog),callback:function ($$v) {_vm.isDialog=$$v},expression:"isDialog"}},[_c('div',[_c('div',{staticClass:"px-5"},[_c('v-checkbox',{attrs:{"label":_vm.$trans('hint.meeting_invite.is_send_to_group')},model:{value:(_vm.isSendToGroup),callback:function ($$v) {_vm.isSendToGroup=$$v},expression:"isSendToGroup"}}),_c('div',{staticClass:"caption mb-2"},[_vm._v(_vm._s(_vm.$trans('hint.meeting_invite.additional_emails')))]),_c('v-combobox',{attrs:{"filter":_vm.filter,"hide-no-data":!_vm.search,"items":_vm.additionalEmailsItems,"search-input":_vm.search,"hide-selected":"","label":_vm.$trans('Recipients'),"error":_vm.isAdditionalEmailsInvalid,"rules":[_vm.rules.emailValidator],"multiple":"","small-chips":"","outlined":"","clearable":""},on:{"update:searchInput":function($event){_vm.search=$event},"update:search-input":function($event){_vm.search=$event},"update:error":function($event){_vm.isAdditionalEmailsInvalid=$event},"input":function($event){_vm.isAdditionalEmailsInvalid = false}},scopedSlots:_vm._u([{key:"no-data",fn:function(){return [_c('v-list-item',[_c('span',{staticClass:"pr-2 caption font-weight-bold"},[_vm._v(_vm._s(_vm.$trans('Add')))]),_c('v-chip',{attrs:{"label":"","small":""}},[_vm._v(" "+_vm._s(_vm.search)+" ")])],1)]},proxy:true},{key:"selection",fn:function(ref){
var attrs = ref.attrs;
var item = ref.item;
var parent = ref.parent;
var selected = ref.selected;
return [(item === Object(item))?_c('v-chip',_vm._b({attrs:{"input-value":selected,"label":"","small":""}},'v-chip',attrs,false),[_c('span',{staticClass:"pr-2"},[_vm._v(" "+_vm._s(item.text)+" ")]),_c('v-icon',{attrs:{"small":""},on:{"click":function($event){return parent.selectItem(item)}}},[_vm._v(" $delete ")])],1):_vm._e()]}},{key:"item",fn:function(ref){
var index = ref.index;
var item = ref.item;
return [(_vm.editing === item)?_c('v-text-field',{attrs:{"autofocus":"","flat":"","background-color":"transparent","hide-details":"","solo":""},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.edit(index, item)}},model:{value:(_vm.editing.text),callback:function ($$v) {_vm.$set(_vm.editing, "text", $$v)},expression:"editing.text"}}):_c('v-chip',{attrs:{"label":"","small":""}},[_vm._v(" "+_vm._s(item.text)+" ")]),_c('v-spacer'),_c('v-list-item-action',{on:{"click":function($event){$event.stopPropagation();}}},[_c('div',{staticClass:"d-flex align-center"},[_c('v-btn',{attrs:{"icon":"","small":"","color":"success"},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.edit(index, item)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v(_vm._s(_vm.editing !== item ? 'mdi-pencil' : 'mdi-check'))])],1),_c('v-btn',{attrs:{"icon":"","small":"","color":"error"},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.onDeleteAdditionalItem(index, item)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-close")])],1)],1)])]}}],null,false,151931380),model:{value:(_vm.additionalEmails),callback:function ($$v) {_vm.additionalEmails=$$v},expression:"additionalEmails"}})],1),_c('v-divider',{staticClass:"my-4"}),_c('div',{staticClass:"d-flex px-5 pb-4"},[_c('v-spacer'),_c('v-btn',{attrs:{"disabled":_vm.isSubmitDisabled,"loading":_vm.loading,"color":"primary"},on:{"click":_vm.onSendInvite}},[_vm._v(" "+_vm._s(_vm.$trans('Send'))+" "),_c('w-icon',{attrs:{"value":"SEND","right":""}})],1)],1)],1)])],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }