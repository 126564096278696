<template>
    <div v-if="item">
        <v-img
            v-if="cover"
            height="160"
            width="100%"
            src="@/assets/images/pages/developerMeetup.png"
            class="mb-5"
        />
        <v-card-text class="d-flex align-center pa-0">
            <v-avatar
                size="54"
                rounded
                color="primary"
                class="v-avatar-light-bg primary--text mt-1"
            >
                <div>
                    <span class="font-weight-medium">{{ begin | dateFormat('MMM') }}</span>
                    <p class="text-xl primary--text font-weight-bold mb-0">{{ begin | dateFormat('DD') }}</p>
                </div>
            </v-avatar>
            <div
                v-ripple
                class="ms-2 pa-1 cursor-pointer d-flex align-center flex-grow-1"
                @click="pushToCourseDetails"
            >
                <div>
                    <h4 class="text-base font-weight-semibold">{{ course }}</h4>
                    <p class="text-xs mb-0">{{ lessonType }}</p>
                </div>
                <v-spacer/>
                <w-icon color="primary" value="NEXT"/>
            </div>
        </v-card-text>
        <v-card-text class="my-4 pa-0">
            <div class="my-4 pa-0">
                <v-divider></v-divider>
            </div>
            <w-options-list
                :items="details"
            />
            <div class="my-4 pa-0">
                <v-divider></v-divider>
            </div>
            <member-full-name
                v-if="!isMemberTeacher"
                :item="teacher"
                :subtitle="department"
            />
            <div v-else-if="isMemberTeacher && item.groups">
                <w-icon value="GROUP" left/>
                <template
                    v-for="(group, index) in item.groups"
                >
                    <template v-if="index > 0">,&nbsp;</template>
                    <router-link
                        :key="group.uuid"
                        :to="{name: 'groups.students', params: {uuid: group.uuid}}"
                    >{{ group.title }}</router-link>
                </template>
            </div>
        </v-card-text>
    </div>
</template>

<script>
import _ from 'lodash'
import moment from 'moment'
import MemberFullName from '@/components/Member/MemberFullName'
import lessonDetailsPropertiesMixin from './mixins/lessonDetailsPropertiesMixin'

export default {
    name: 'ScheduleLessonDetails',
    mixins: [
        lessonDetailsPropertiesMixin
    ],
    components: { MemberFullName },
    props: {
        item: {
            type: Object,
            default: null
        }
    },
    computed: {
        locale() {
            return this.$store.getters.locale
        },
        details() {
            moment.updateLocale(this.locale)

            return [
                {
                    icon: 'CLOCK',
                    title: `${_.upperFirst(moment(this.begin).format('dddd, DD MMMM, HH:mm'))} - ${moment(this.end).format('HH:mm')}`,
                    subtitle: moment(this.begin).fromNow()
                },
                {
                    icon: this.locationIcon,
                    title: this.location.title,
                    subtitle: this.location.subtitle
                }
            ]
        }
    },
    methods: {
        pushToCourseDetails() {
            this.$router.push({ name: 'courses.details', params: { uuid: this.item.course.uuid } })
        }
    }
}
</script>

<style lang=scss>

</style>
